import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/contact-us.scss"

const ThankYouPage = () => (
  <Layout bodyClass="contact thank-you">
    <Seo
      title="Thank You"
      description="Thanks for your interest in SIMPAS-applied Solutions™ and the SIMPAS® application system."
      robots="noindex, nofollow"
    />
    <section className="bg--black">
      <div className="container">
        <div className="text-center flow">
          <h1>Contact Us</h1>
        </div>
      </div>
    </section>
    <section className="bg--white thank-you-content" id="thank-you">
      <div className="container bg--blue bg--shadow padding--tb padding--lr radius--md flow">
        <p className="thank-you-heading text-center">
          Thanks for your interest in SIMPAS-applied Solutions™ and the SIMPAS<sup>®</sup> application system.
        </p>
        <p className="thank-you-text text-center">
          We'll be in touch within 2 business days to answer your questions.
        </p>
      </div>
    </section>
  </Layout>
)

export default ThankYouPage
